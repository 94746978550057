//*** Import React Library
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Modal from 'react-bootstrap/Modal';
import Axios from 'axios'
import "../../Assets/Styles/robot.css";
import { hasJWTToken } from "../Account/RouteGuard";
import "../../Assets/Styles/Form.css";
import * as FaIcons from "react-icons/fa";
const edit_robot = (robotId) => {
    sessionStorage.removeItem("editRobotId");
    sessionStorage.setItem("editRobotId", robotId);
    window.location.href = "#/EditRobot?rbtid=" + robotId + "";
};
//*** Create custom style for react table
const customStyles = {
    table: {
        style: {
            border: "none",
            fontSize: "100%"
        },
    },
    header: {
        backgroundColor: "transparent",
    },
    rows: {
        style: {
            backgroundColor: "transparent",
        },
    },
    headRow: {
        style: {
            backgroundColor: "transparent",
            fontSize: "110%"
        },
    },
    pagination: {
        style: {
            backgroundColor: "transparent",
            fontWeight: "600",
        },
    },
    headCells: {
        style: {
            borderBottom: "none",
            fontWeight: "700",
            fontSize: "110%"
        },
    },
};
export const SearchRobot = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [robotData, setRobotData] = useState([]);
    const [robotList, setRobotList] = useState([]);
    const [robotID, setRobotID] = useState("");
    const [robotName, setRobotName] = useState("");
    //*** Create columns for react table
    const columns = [
        /*{ name: "ID", selector: (row) => row.robot_id, sortable: true, },*/
        { name: "ID", selector: (row) => <a className="alink" onClick={() => edit_robot(row.robot_id)}>{row.robot_id}</a>, sortable: true, },
        { name: "Plant", selector: (row) => row.plant_name, sortable: true, },
        { name: "Robot", selector: (row) => row.robot_name, sortable: true },
        { name: "Robot Code", selector: (row) => row.robot_code, sortable: true },
        { name: "Wheel Perimeter", selector: (row) => row.wheel_perimeter, sortable: true },
        { name: "Sending Topic", selector: (row) => row.mqtt_sending_topic, sortable: true },
        { name: "Receving Topic", selector: (row) => row.mqtt_receiving_topic, sortable: true },
        { name: "Critical Battery Level", selector: (row) => row.critical_battery_level, sortable: true },
        { name: "Date", selector: (row) => row.robot_added_date, sortable: true },
        { name: "Delete", selector: (row) => <a className="alink" onClick={() => showModal(row.robot_id, row.robot_name)}>Delete</a>, sortable: true, },
    ];
    const showModal = (robot_id, robot_name) => {
        setRobotID(robot_id);
        setRobotName(robot_name);
        var divmodal = document.getElementById("dvmodal");
        var divMessage = document.getElementById("dvMessage");
        divmodal.style.display = "block";
        divMessage.innerText = "are you sure to delete robot ID '" + robot_id + "'?"
        divmodal.scrollIntoView();
    };
    const hideModal = (dvmodal) => {
        var dvimodal = document.getElementById(dvmodal);
        dvimodal.style.display = "none";
    };
    const deleteRecord = async () => {
        var user_id = sessionStorage.getItem("user_id");
        let response = await Axios("/PlantRobot/DeleteRobot?rbtid=" + robotID + "&uid=" + user_id + "&pn=DeleteRobot");
        hideModal("dvmodal");
        fetchRobotData();
    }
    //*** Seach Textbox text change event
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        let tempList = robotData;
        const filteredData = tempList.filter((event) =>
            Object.values(event).some(
                (value) => typeof value === "string" && value.includes(searchTerm)
            )
        );
        if (event.target.value != null) {
            setRobotList(filteredData);
        }
        if (event.target.value === "") {
            setRobotList(robotData);
        }
    };
    //*** Filter table
    const filteredData = robotList.filter((event) =>
        Object.values(event).some(
            (value) => typeof value === "string" && value.includes(searchTerm)
        )
    );
    //*** Use React hooks - if any changes on page then useffect() function will run auto
    useEffect(() => {
        fetchRobotData();
    }, [])
    useEffect(() => {
        console.log(robotData)
    }, [robotData])
    //*** Fetch data from API 
    const fetchRobotData = async () => {
        var robot_id = 1;//sessionStorage.getItem("editRobotId");
        var user_id = sessionStorage.getItem("user_id");
        var _token = sessionStorage.getItem("token");
        if (_token) _token = "Token exists";
        let response = await Axios("/PlantRobot?rbtid=" + robot_id + "&uid=" + user_id + "&pn=SearchRobot&sn=SearchAllRobot");
        setRobotData(response.data)
        setRobotList(response.data)
    }
    return (
        <div className="page-container">
         <div className="search-container">
                <h2 className="form-title"><FaIcons.FaSearch /> Search Robot</h2>
                <div class="row">
                    <div class="col-1"></div>
                    <div class="col-12"><a className="btndelete" href="#/PlantRobot">Add New Robot</a></div>
                </div>
             <div>
                <div
                    style={{
                        border: "1px solid #c0c0c0",
                        borderRadius: "5px",
                        margin:"2%",
                        backgroundColor: "#fff",
                    }}
                >
                    <div className="container dvscroll" >
                        <div className="row dvscroll">
                            <div className="col-sm-12">
                                <input
                                    type="text"
                                    placeholder="Search Results"
                                    style={{
                                        width: "20%",
                                        height: "30px",
                                        margin: "10px",
                                        float: "right",
                                        border: "none",
                                        padding: "1.5%",
                                        borderRadius: "5px",
                                    }}
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                                <p></p>
                                <DataTable className="table .table-sm table-hover" sort={true} pagination={true}
                                    search={true}
                                    columns={columns}
                                    data={filteredData}
                                    highlightOnHover={true}
                                    customStyles={customStyles}
                                >
                                </DataTable>
                            </div>
                        </div>
                        </div>
                        <div id="dvmodal" class="modalcontainer">
                            <Modal.Dialog size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton onClick={() => hideModal("dvmodal")}>
                                    <Modal.Title>
                                        Delete Robots
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>
                                        <div id="dvMessage"></div>
                                    </p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <a variant="secondary" className="btndelete" onClick={() => deleteRecord()}>
                                        Delete
                                    </a>
                                    <a variant="secondary" className="btncancel" onClick={() => hideModal("dvmodal")}>
                                        Cancel
                                    </a>
                                </Modal.Footer>
                            </Modal.Dialog>
                        </div> 
                </div>
            </div>
            </div>
        </div>
    );
}
export default SearchRobot;