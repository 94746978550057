// Farm.js
import React, { useState, useEffect } from 'react';
import { hasJWTToken } from "../Account/RouteGuard";
import "../../Assets/Styles/tab.css";
import "../../Assets/Styles/Form.css";
import {AddFarm} from "../Account/AddFarm";
import { SearchFarm } from "../Account/SearchFarm";
import { EditFarm } from "../Account/EditFarm";
import * as FaIcons from "react-icons/fa";
var _mode;
export const openTab = (pageName, elmnt, color, mode, pid) => {
    _mode = mode;
    sessionStorage.setItem("editfid", pid);
   // EditFarm(pid);
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablink");
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].style.backgroundColor = "";
        //tablinks[i].style.color = "";
    }
    document.getElementById(pageName).style.display = "block";
    document.getElementById(elmnt).style.backgroundColor = color;
    window.location.href = "/EditFarm";
};
export function Farm () {
    const [farmMode, setFarmMode] = useState("Add");
    const _userID = sessionStorage.getItem("user_id");
    let _token = sessionStorage.getItem("token");
    if (_token) _token = "Token exists";
    let [responseData, setResponseData] = useState();
    //alert(_mode + ' farm');
    useEffect(() => {
        setFarmMode(farmMode);
        openTab('AddFarm', 'btnAddFarm', '#fff', "Add");
    }, [])
    useEffect(() => {
        console.log(farmMode)
    }, [farmMode])
   
    return (
      <div className="container-ul">
        <div className="form-container1">
            <p></p>
            <button className="tablink" id="btnAddFarm" onClick={() => openTab('AddFarm', 'btnAddFarm', '#fff')}><FaIcons.FaFileMedical/> Add New Farm</button>
            <button className="tablink" id="btnSearchFarm" onClick={() => openTab('SearchFarm', 'btnSearchFarm', '#fff')}><FaIcons.FaSearch /> Search Farm</button>
            <button className="tablink" id="btnEditFarm" onClick={() => openTab('EditFarm', 'btnEditFarm', '#fff')}><FaIcons.FaRegEdit /> Edit Farm</button>
            <div id="AddFarm" className="tabcontent">
               <AddFarm></AddFarm>
            </div>
            <div id="SearchFarm" className="tabcontent">
                <SearchFarm></SearchFarm>
            </div>
            <div id="EditFarm" className="tabcontent">
            <EditFarm></EditFarm>
            </div>
            </div>
      </div>
            
    );
}
export default Farm;