//*** AddRow.js
import React, { useState, useEffect } from 'react';
import "../../Assets/Styles/Form.css";
import Axios from 'axios';
import * as FaIcons from "react-icons/fa";
import { hasJWTToken } from "../Account/RouteGuard";
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
export const PlantRow = () => {
    const _userID = sessionStorage.getItem("user_id");
    let _token = sessionStorage.getItem("token");
    if (_token) _token = "Token exists";
    let [responseData, setResponseData] = useState([]);
    const [plantOption, setPlantOption] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    let [formData, setFormData] = useState({
        plant_id: null,
        row_id: null,
        row_name: '',
        user_id: _userID,
        jwt_token: _token,
        block_mode: 'Insert',
        message: '',
    });
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        var newErrors = validateForm(formData);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            // Form submission logic here
            formData.row_mode = "Insert";
            formData.user_id = 1;
            let objRowData = Axios.post("/PlantRow", formData)
           // formData.plant_id = null;
            formData.row_id = null;
            formData.row_name = '';
            setFormData(formData);
            formData.message = "Added new row successfully!";
            console.log('Form submitted successfully!');
        } else {
            formData.message = 'Row submission failed due to validation errors.'
            console.log('Form submission failed due to validation errors.');
        }
    };
    const validateForm = (data) => {
        const errors = {};
        if (!data.plant_id.trim()) {
            errors.plant_id = 'Plant ID is required';
        } else if (data.plant_id.length > 10) {
            errors.plant_id = 'Plant ID should not be less than or more than 10 digits';
        }
        if (!data.row_name.trim()) {
            errors.row_name = 'Row name is required';
        } else if (data.row_name.length > 50) {
            errors.row_name = 'Row name should not be less than or more than 50 characters';
        }
        return errors;
    };
    const handleSelectChange = (e) => {
        formData.plant_id = e.target.value;
    };
    useEffect(() => {
        fetchPlantList();
    }, [])
    useEffect(() => {
        console.log(plantOption)
    }, [plantOption])
    const fetchPlantList = async () => {
        var userID = sessionStorage.getItem("user_id");
        let response = await Axios("/PlantBlock/GetPlantList?paramid=1&=uid=" + userID + "&pn=PlantBlock&dn=PlantList");
        setPlantOption(response.data);
    }
    return (
        <div className="container-ul">
            <div className="form-container">
            <h2 className="form-title"><FaIcons.FaRegEdit /> Add New Row</h2>
            <span className="form-message">
                {formData.message}
            </span>
            <form onSubmit={handleSubmit}>
                <div>
                    <Form.Select name="plant_id" onChange={handleSelectChange}>
                        {plantOption.map(opt => (
                            <option value={opt.plant_id}>{opt.plant_name}</option>
                        ))}
                    </Form.Select>
                </div>
                <div>
                    <input
                        className="form-input"
                        type="text"
                        name="row_name"
                        maxLength="50"
                        value={formData.row_name}
                        onChange={handleChange}
                        placeholder="Enter block name (Max limit 50 chars)"
                    />
                    {errors.row_name && (
                        <span className="error-message">
                            {errors.row_name}
                        </span>
                    )}
                </div>
                <div class="row">
                    <div class="col-9"><button className="submit-button" type="submit">Submit</button></div>
                    <div class="col-3"><a className="btndelete" href="#/SearchRow">Search Row</a></div>
                </div>
            </form>
            </div>
        </div>
    );
}
export default PlantRow;