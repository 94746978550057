//*** Import React Library
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Axios from 'axios'
import "../../Assets/Styles/robot.css";
import { hasJWTToken } from "../Account/RouteGuard";
import "../../Assets/Styles/Form.css";
import * as FaIcons from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
const edit_row = (rowId) => {
    sessionStorage.removeItem("editRowId");
    sessionStorage.setItem("editRowId", rowId);
    window.location.href = "#/EditRow?rid=" + rowId + "";
};
//*** Create custom style for react table
const customStyles = {
    table: {
        style: {
            border: "none",
            fontSize: "100%"
        },
    },
    header: {
        backgroundColor: "transparent",
    },
    rows: {
        style: {
            backgroundColor: "transparent",
        },
    },
    headRow: {
        style: {
            backgroundColor: "transparent",
            fontSize: "110%"
        },
    },
    pagination: {
        style: {
            backgroundColor: "transparent",
            fontWeight: "600",
        },
    },
    headCells: {
        style: {
            borderBottom: "none",
            fontWeight: "700",
            fontSize: "110%"
        },
    },
};
export const SearchRow = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [rowData, setRowData] = useState([]);
    const [rowList, setRowList] = useState([]);
    const [rowID, setRowID] = useState("");
    const [rowName, setRowName] = useState("");
    //*** Create columns for react table
    const columns = [
        { name: "ID", selector: (row) => <a className="alink" onClick={() => edit_row(row.row_id)}>{row.row_id}</a>, sortable: true, },
        { name: "Plant Name", selector: (row) => row.plant_name, sortable: true, },
        { name: "Row Name", selector: (row) => row.row_name, sortable: true },
        { name: "Date", selector: (row) => row.row_added_date, sortable: true },
        { name: "Delete", selector: (row) => <a className="alink" onClick={() => showModal(row.row_id, row.row_name)}>Delete</a>, sortable: true, },
    ];
    //*** Show Modal
    const showModal = (row_id, row_name) => {
        setRowID(row_id);
        setRowName(row_name);
        var divmodal = document.getElementById("dvmodal");
        var divMessage = document.getElementById("dvMessage");
        divmodal.style.display = "block";
        divMessage.innerText = "are you sure to delete row ID '" + row_id + "'?"
        divmodal.scrollIntoView();
    };
    //*** Hide Modal
    const hideModal = (dvmodal) => {
        var dvimodal = document.getElementById(dvmodal);
        dvimodal.style.display = "none";
    };
    //*** Delete Records
    const deleteRecord = async () => {
        var user_id = sessionStorage.getItem("user_id");
        let response = await Axios("/PlantRow/DeleteRow?rid=" + rowID + "&uid=" + user_id + "&pn=DeleteRow");
        hideModal("dvmodal");
        fetchRowData();
    }
    //*** Seach Textbox text change event
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        let tempList = rowData;
        const filteredData = tempList.filter((event) =>
            Object.values(event).some(
                (value) => typeof value === "string" && value.includes(searchTerm)
            )
        );
        if (event.target.value != null) {
            setRowList(filteredData);
        }
        if (event.target.value === "") {
            setRowList(rowData);
        }
    };
    //*** Filter table
    const filteredData = rowList.filter((event) =>
        Object.values(event).some(
            (value) => typeof value === "string" && value.includes(searchTerm)
        )
    );
    //*** Use React hooks - if occur any changes on page then useffect() function will run auto
    useEffect(() => {
        fetchRowData();
    }, [])
    useEffect(() => {
        console.log(rowData)
    }, [rowData])
    //*** Fetch data from API // To Search 94fbr
    const fetchRowData = async () => {
        var row_id = 1 //sessionStorage.getItem("editBlockId");
        var user_id = sessionStorage.getItem("user_id");
        var _token = sessionStorage.getItem("token");
        let response = await Axios("/PlantRow?rid="+row_id+"&uid="+user_id+"&pn=SearchRow&sn=SearchAllRow");
        setRowData(response.data)
        setRowList(response.data)
    }
    return (
        <div className="page-container">
            <div className="search-container">
                <h2 className="form-title"><FaIcons.FaSearch /> Search Row</h2>
                <div class="row">
                    <div class="col-1"></div>
                    <div class="col-12"><a className="btndelete" href="#/PlantRow">Add New Row</a></div>
                </div>
                <div>
                    <div
                        style={{
                            border: "1px solid #c0c0c0",
                            borderRadius: "5px",
                            margin: "2%",
                            backgroundColor: "#fff",
                        }}
                    >
                        <div className="container dvscroll" >
                            <div className="row dvscroll">
                                <div className="col-sm-12">
                                    <input
                                        type="text"
                                        placeholder="Search Results"
                                        style={{
                                            width: "20%",
                                            height: "30px",
                                            margin: "10px",
                                            float: "right",
                                            border: "none",
                                            padding: "1.5%",
                                            borderRadius: "5px",
                                        }}
                                        value={searchTerm}
                                        onChange={handleSearch}
                                    />
                                    <p></p>
                                    <DataTable className="table .table-sm table-hover" sort={true} pagination={true}
                                        search={true}
                                        columns={columns}
                                        data={filteredData}
                                        highlightOnHover={true}
                                        customStyles={customStyles}
                                    >
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="dvmodal" class="modalcontainer">
                    <Modal.Dialog size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton onClick={() => hideModal("dvmodal")}>
                            <Modal.Title>
                                Delete Rows
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                <div id="dvMessage"></div>
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <a variant="secondary" className="btndelete" onClick={() => deleteRecord()}>
                                Delete
                            </a>
                            <a variant="secondary" className="btncancel" onClick={() => hideModal("dvmodal")}>
                                Cancel
                            </a>
                        </Modal.Footer>
                    </Modal.Dialog>
                </div>
            </div>
        </div>
    );
}
export default SearchRow;