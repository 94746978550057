import { Home } from "./components/Home/Home";
import { Sidebar } from "./components/Shared/Sidebar";
import { SidebarData } from "./components/Shared/SidebarData";
import { Dashboard } from "./components/Dashboard/Dashboard";
//import { Account, User, Company, Login } from "./components/Account/Account";
import { Login } from "./components/Account/Login";
import { Logout } from "./components/Account/Logout";
import { RobotsOperation } from "./components/RobotOperation/RobotsOperation";
import { RobotOperation } from "./components/RobotOperation/RobotOperation";
import { PlantRobotOperation} from "./components/RobotOperation/PlantRobotOperation";
import { PlantRobotsOperationStatus } from "./components/RobotOperation/PlantRobotsOperationStatus";
import { RobotLocation } from "./components/RobotOperation/RobotLocation";
import { MapComponent } from "./components/RobotOperation/MapComponent";
import { DistanceCalculator } from "./components/RobotOperation/DistanceCalculator";
import { TestMap } from "./components/RobotOperation/TestMap";
import { PlantRobotsBroadcastStatus } from "./components/Monitoring/PlantRobotsBroadcastStatus";
import { CurrentCommandStatus } from "./components/Monitoring/CurrentCommandStatus";
import { CompletedCommandsStatus } from "./components/Monitoring/CompletedCommandsStatus";
import { PendingCommandsStatus } from "./components/Monitoring/PendingCommandsStatus";
import { CurrentBatteryStatus } from "./components/Monitoring/CurrentBatteryStatus";
import { CurrentPlantLevelGrassCutting } from "./components/Monitoring/CurrentPlantLevelGrassCutting";
import { ChartCurrentGrassCuttingSpraying } from "./components/Monitoring/ChartCurrentGrassCuttingSpraying";
import { ChartCurrentPlantLevelGrassCutting } from "./components/Monitoring/ChartCurrentPlantLevelGrassCutting";
import { ChartGrassCuttingByMonths } from "./components/Monitoring/ChartGrassCuttingByMonths";
import { ChartGrassCuttingByHours } from "./components/Monitoring/ChartGrassCuttingByHours";
import { CurrentPlantLevelSpraying } from "./components/Monitoring/CurrentPlantLevelSpraying";
import { ChartCurrentPlantLevelSpraying } from "./components/Monitoring/ChartCurrentPlantLevelSpraying";
import { ChartSprayingByMonths } from "./components/Monitoring/ChartSprayingByMonths";
import { ChartSprayingByHours } from "./components/Monitoring/ChartSprayingByHours";
import { CurrentRobotLevelGrassCutting } from "./components/Monitoring/CurrentRobotLevelGrassCutting";
import { ChartCurrentRobotLevelGrassCutting} from "./components/Monitoring/ChartCurrentRobotLevelGrassCutting";
import { CurrentRobotLevelSpraying } from "./components/Monitoring/CurrentRobotLevelSpraying";
import { ChartCurrentRobotLevelSpraying } from "./components/Monitoring/ChartCurrentRobotLevelSpraying";
import { Plant } from "./components/Account/Plant";
import { AddPlant } from "./components/Account/AddPlant";
import { EditPlant } from "./components/Account/EditPlant";
import { SearchPlant } from "./components/PlantEquipmentSetup/SearchPlant";
import { PlantRobot } from "./components/PlantEquipmentSetup/PlantRobot";
import { SearchRobot } from "./components/PlantEquipmentSetup/SearchRobot";
import { PlantBlock } from "./components/PlantEquipmentSetup/PlantBlock";
import { SearchBlock } from "./components/PlantEquipmentSetup/SearchBlock";
import { PlantRow } from "./components/PlantEquipmentSetup/PlantRow";
import { SearchRow } from "./components/PlantEquipmentSetup/SearchRow";
import { PlantController } from "./components/PlantEquipmentSetup/PlantController";
import { SearchController} from "./components/PlantEquipmentSetup/SearchController";
import { Farm } from "./components/Account/Farm";
import { AddFarm } from "./components/Account/AddFarm";
import { EditFarm } from "./components/Account/EditFarm";
import { SearchFarm } from "./components/Account/SearchFarm";
//import { GrassCutting } from "./components/Reports/GrassCutting";
//import { Home }  from "./components/Monitoring/Home";
//import { SelectTableComponent }  from "./components/RobotOperation/SelectTableComponent";
//import { PlantRobotsInfo1 } from "./components/RobotOperation/PlantRobotsInfo1";
/*import { LoginView } from "./components/Account/Login";*/
//import { Counter } from "./components/Counter";
//import { FetchData } from "./components/FetchData";
//import { LineChart } from "./components/RobotOperation/LineChart";

//import { PlantEquipmentSetup } from "./components/PlantEquipmentSetup/PlantEquipmentSetup";
//import { PlantBlocks } from "./components/PlantEquipmentSetup/PlantBlocks";
//import { SearchBlocks } from "./components/PlantEquipmentSetup/SearchBlocks";
//import { PlantRows } from "./components/PlantEquipmentSetup/PlantRows";
//import { SearchRows } from "./components/PlantEquipmentSetup/SearchRows";
//import { PlantControllers } from "./components/PlantEquipmentSetup/PlantControllers";
//import { SearchControllers } from "./components/PlantEquipmentSetup/SearchControllers";
//import { PlantRobots } from "./components/PlantEquipmentSetup/PlantRobots";
//import { SearchRobots } from "./components/PlantEquipmentSetup/SearchRobots";
//import { BlockRows } from "./components/PlantEquipmentLinking/BlockRows";
//import { BlockControllers } from "./components/PlantEquipmentLinking/BlockControllers";
//import { BlockRobots } from "./components/PlantEquipmentLinking/BlockRobots";
//import { EquipmentLinkingDetails} from "./components/PlantEquipmentLinking/EquipmentLinkingDetails";
//import { MyMap } from "./components/RobotOperation/MyMap";
//import { Map } from "./components/RobotOperation/Map";

const AppRoutes = [
  {
     index: true,
     path: '/Login',
     element: <Login/>
  },
    {
    
        path: '/MapComponent',
        element: <MapComponent />
    },
  {
     path: '/RobotLocation',
     element: <RobotLocation />,
  },
  {
      path: '/DistanceCalculator',
      element: <DistanceCalculator />,
  },
  {
        path: '/TestMap',
        element: <TestMap />,
  },
  {
      path: '/RobotsOperation',
      element: <RobotsOperation/>
  },
  {
      path: '/RobotOperation',
        element: <RobotOperation />
  },
  {
    path: '/RobotsBroadcastStatus',
      element: <PlantRobotsBroadcastStatus/>
  },
  {
    path: '/PlantRobotsOpnStatus',
    element: <PlantRobotsOperationStatus/>
  },
  {
      path: '/CurrentCommandStatus',
      element: <CurrentCommandStatus/>
  },
  {
      path: '/PendingCommandsStatus',
      element: <PendingCommandsStatus />
  },
  {
      path: '/CompletedCommandsStatus',
      element: <CompletedCommandsStatus />
  },
  {
      path: '/CurrentBatteryStatus',
      element: <CurrentBatteryStatus/>
  },
  {
      path: '/CurrentPlantLevelGrassCutting',
      element: <CurrentPlantLevelGrassCutting/>
  },
  {
      path: '/ChartCurrentGrassCuttingSpraying',
      element: <ChartCurrentGrassCuttingSpraying/>
  },
  {
      path: '/ChartCurrentPlantLevelGrassCutting',
      element: <ChartCurrentPlantLevelGrassCutting />
  },
  {
      path: '/ChartGrassCuttingByMonths',
      element: <ChartGrassCuttingByMonths />
  },
  {
        path: '/ChartGrassCuttingByHours',
        element: <ChartGrassCuttingByHours />
  },
  {
      path: '/CurrentPlantLevelSpraying',
      element: <CurrentPlantLevelSpraying/>
  },
  {
      path: '/ChartCurrentPlantLevelSpraying',
      element: <ChartCurrentPlantLevelSpraying/>
  },
  {
      path: '/ChartSprayingByMonths',
      element: <ChartSprayingByMonths />
  },
  {
        path: '/ChartSprayingByHours',
        element: <ChartSprayingByHours />
  },
  {
      path: '/CurrentRobotsLevelGrassCutting',
      element: <CurrentRobotLevelGrassCutting/>
  },
  {
      path: '/ChartCurrentRobotLevelGrassCutting',
      element: <ChartCurrentRobotLevelGrassCutting />
  },
  {
      path: '/CurrentRobotsLevelSpraying',
      element: <CurrentRobotLevelSpraying/>
  },
  {
      path: '/ChartCurrentRobotsLevelSpraying',
      element: <ChartCurrentRobotLevelSpraying />
  },
  {
    path: "/PlantRobotOperation",
    element: <PlantRobotOperation/>,
  },
  //{
  //      path: '/LineChart',
  //      element: <LineChart />
  //},
  {
    path: '/Dashboard',
    element: <Dashboard/>
    },
    {
        path: '/Plant',
        element: <Plant/>
    },
    {
        path: '/AddPlant',
        element: <AddPlant />
    },
    {
        path: '/EditPlant',
        element: <EditPlant />
    },
    {
        path: '/SearchPlant',
        element: <SearchPlant/>
    },
    {
        path: '/PlantRobot',
        element: <PlantRobot/>
    },
    {
        path: '/SearchRobot',
        element: <SearchRobot />
    },
  
    {
        path: '/Farm',
        element: <Farm />
    },
    {
        path: '/AddFarm',
        element: <AddFarm />
    },
    {
        path: '/EditFarm',
        element: <EditFarm/>
    },
    {
        path: '/SearchFarm',
        element: <SearchFarm />
    },
    {
        path: '/PlantBlock',
        element: <PlantBlock/>
    },
    {
        path: '/SearchBlock',
        element: <SearchBlock />
    },
    {
        path: '/PlantRow',
        element: <PlantRow/>
    },
    {
        path: '/SearchRow',
        element: <SearchRow/>
    },
    {
        path: '/PlantController',
        element: <PlantController />
    },
    {
        path: '/SearchController',
        element: <SearchController/>
    },
  ////{
  ////  path: '/allSupervillain',
  ////  element: <AllSuperVillain />
  ////},
  //{
  //  path: "/PlantRobotOpn",
  //  element: <PlantRobotOperation/>,
  // },
  // {
  //      path: "/Map",
  //      element: <Map/>,
  // },
  //{
  //    path: "/Mymap",
  //    element: <MyMap />,
  //},
  //{
  //  path: '/RobotsOperation',
  //  element: <RobotsOperation/>
  //},
  //{
  //      path: '/GrassCutting',
  //      element: <GrassCutting />
  //},
  ////{
  ////  path: "/login1",
  ////  element: <LoginView />,
  ////},
  //{
  //  path: "/Home",
  //  element: <Home />,
  //},
  //{
  //  path: '/plantequipmentsetup',
  //  element: <PlantEquipmentSetup/>
  //},
  //{
  //  path: '/equipmentlinkingdetails',
  //  element: <EquipmentLinkingDetails/>
  //},
  //{
  //  path: '/account',
  //  element: <Account/>
  //},
  //{
  //  path: '/plantblocks',
  //  element: <PlantBlocks/>
  //},
  //{
  //  path: '/plantrows',
  //  element: <PlantRows/>
  //},
  //{
  //  path: '/plantrobots',
  //  element: <PlantRobots/>
  //},
  //{
  //  path: '/plantcontrollers',
  //  element: <PlantControllers/>
  //},
  //{
  //  path: '/searchblocks',
  //  element: <SearchBlocks/>
  //},
  //{
  //  path: '/searchrows',
  //  element: <SearchRows/>
  //},
  //{
  //  path: '/searchcontrollers',
  //  element: <SearchControllers/>
  //},
  //{
  //  path: '/searchrobots',
  //  element: <SearchRobots/>
  //},
  //{
  //  path: '/blockrows',
  //  element: <BlockRows/>
  //},
  //{
  //  path: '/blockrobots',
  //  element: <BlockRobots/>
  //},
  //{
  //  path: '/blockcontrollers',
  //  element: <BlockControllers/>
  //  },
    //{
    //    path: '/selecttablecomponent',
    //    element: <SelectTableComponent />
    //},
    
    //{
    //    path: '/counter',
    //    element: <Counter />
    //},
    //{
    //    path: '/LineChart',
    //    element: <LineChart />
    //}
    //,
    //{
    //    path: '/fetch-data',
    //    element: <FetchData />
    //}
];
export default AppRoutes;
