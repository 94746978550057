//*** Import React Library
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Axios from 'axios'
import "../../Assets/Styles/robot.css";
import { hasJWTToken } from "../Account/RouteGuard";
import "../../Assets/Styles/Form.css";
import * as FaIcons from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
const edit_controller = (controllerId) => {
    sessionStorage.removeItem("editControllerId");
    sessionStorage.setItem("editControllerId", controllerId);
    window.location.href = "#/EditBlock?bid=" + controllerId + "";
};
//*** Create custom style for react table
const customStyles = {
    table: {
        style: {
            border: "none",
            fontSize: "100%"
        },
    },
    header: {
        backgroundColor: "transparent",
    },
    rows: {
        style: {
            backgroundColor: "transparent",
        },
    },
    headRow: {
        style: {
            backgroundColor: "transparent",
            fontSize: "110%"
        },
    },
    pagination: {
        style: {
            backgroundColor: "transparent",
            fontWeight: "600",
        },
    },
    headCells: {
        style: {
            borderBottom: "none",
            fontWeight: "700",
            fontSize: "110%"
        },
    },
};
export const SearchController = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [controllerData, setControllerData] = useState([]);
    const [controllerList, setControllerList] = useState([]);
    const [controllerID, setControllerID] = useState("");
    const [controllerName, setControllerName] = useState("");
    //*** Create columns for react table
    const columns = [
        { name: "ID", selector: (row) => <a className="alink" onClick={() => edit_controller(row.controller_id)}>{row.controller_id}</a>, sortable: true, },
        { name: "Plant", selector: (row) => row.plant_name, sortable: true, },
        { name: "Controller", selector: (row) => row.controller_name, sortable: true },
        { name: "Date", selector: (row) => row.controller_added_date, sortable: true },
        { name: "Delete", selector: (row) => <a className="alink" onClick={() => showModal(row.controller_id, row.controller_name)}>Delete</a>, sortable: true, },
    ];
    //** ShowModal
    const showModal = (controller_id, controller_name) => {
        setControllerID(controller_id);
        setControllerName(controller_name);
        var divmodal = document.getElementById("dvmodal");
        var divMessage = document.getElementById("dvMessage");
        divmodal.style.display = "block";
        divMessage.innerText = "are you sure to delete Controller ID '" + controller_id + "'?"
        divmodal.scrollIntoView();
    };
    //** Hide Modal
    const hideModal = (dvmodal) => {
        var dvimodal = document.getElementById(dvmodal);
        dvimodal.style.display = "none";
    };
    //** Delete Records
    const deleteRecord = async () => {
        var user_id = sessionStorage.getItem("user_id");
        let response = await Axios("/PlantControllers/DeleteController?ctrlid=" + controllerID + "&uid=" + user_id + "&pn=DeleteController");
        hideModal("dvmodal");
        fetchControllerData();
    }
    //*** Seach Textbox text change event
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        let tempList = controllerData;
        const filteredData = tempList.filter((event) =>
            Object.values(event).some(
                (value) => typeof value === "string" && value.includes(searchTerm)
            )
        );
        if (event.target.value != null) {
            setControllerList(filteredData);
        }
        if (event.target.value === "") {
            setControllerList(controllerData);
        }
    };
    //*** Filter table
    const filteredData = controllerList.filter((event) =>
        Object.values(event).some(
            (value) => typeof value === "string" && value.includes(searchTerm)
        )
    );
    //*** Use React hooks - if occur any changes on page then useffect() function will run auto
    useEffect(() => {
        fetchControllerData();
    }, [])
    useEffect(() => {
        console.log(controllerData)
    }, [controllerData])
    //*** Fetch data from API // 
    const fetchControllerData = async () => {
        var controller_id = "1" //sessionStorage.getItem("editBlockId");
        var user_id = sessionStorage.getItem("user_id");
        var _token = sessionStorage.getItem("token");
        if (_token) _token = "Token exists";
        let response = await Axios("/PlantControllers?ctrlid=" + controller_id + "&uid=" + user_id + "&pn=SearchController&sn=SearchAllController");
        setControllerData(response.data)
        setControllerList(response.data)
    }
    return (
        <div className="page-container">
            <div className="search-container">
                <h2 className="form-title"><FaIcons.FaSearch /> Search Controller</h2>
                <div class="row">
                    <div class="col-1"></div>
                    <div class="col-12"><a className="btndelete" href="#/PlantController">Add New Controller</a></div>
                </div>
                <div>
                    <div
                        style={{
                            border: "1px solid #c0c0c0",
                            borderRadius: "5px",
                            margin: "2%",
                            backgroundColor: "#fff",
                        }}
                    >
                        <div className="container dvscroll" >
                            <div className="row dvscroll">
                                <div className="col-sm-12">
                                    <input
                                        type="text"
                                        placeholder="Search Results"
                                        style={{
                                            width: "20%",
                                            height: "30px",
                                            margin: "10px",
                                            float: "right",
                                            border: "none",
                                            padding: "1.5%",
                                            borderRadius: "5px",
                                        }}
                                        value={searchTerm}
                                        onChange={handleSearch}
                                    />
                                    <p></p>
                                    <DataTable className="table .table-sm table-hover" sort={true} pagination={true}
                                        search={true}
                                        columns={columns}
                                        data={filteredData}
                                        highlightOnHover={true}
                                        customStyles={customStyles}
                                    >
                                    </DataTable>
                                </div>
                                <div id="dvmodal" class="modalcontainer">
                                    <Modal.Dialog size="lg"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                    >
                                        <Modal.Header closeButton onClick={() => hideModal("dvmodal")}>
                                            <Modal.Title>
                                                Delete Plants
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <p>
                                                <div id="dvMessage"></div>
                                            </p>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <a variant="secondary" className="btndelete" onClick={() => deleteRecord()}>
                                                Delete
                                            </a>
                                            <a variant="secondary" className="btncancel" onClick={() => hideModal("dvmodal")}>
                                                Cancel
                                            </a>
                                        </Modal.Footer>
                                    </Modal.Dialog>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SearchController;