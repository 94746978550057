import React, { useCallback, useRef, useState } from 'react';
import { GoogleMap, useJsApiLoader, Polygon } from '@react-google-maps/api';
const containerStyle = {
    width: '100%',
    height: '400px'
};
// lat: -3.745,
   // lng: -38.523
const center = {
     lat: 18.698715,
     lng: 73.919295
};
export function MapComponent(){
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyDmmBMzFbZmGwc3FMR90OQNy8K3WFFK9eU'
    });
    //{ lat: -3.745, lng: -38.523 },
    //{ lat: -3.745, lng: -38.533 },
    //{ lat: -3.755, lng: -38.533 },
    //{ lat: -3.755, lng: -38.523 }
    const [plantArea, setPlantArea] = useState([
     { lat: 18.698730, lng: 73.919318 },
     { lat: 18.698735, lng: 73.919177 },

    /*{ lat: 18.699098, lng: 73.919206 },*/
   /* { lat: 18.699063, lng: 73.919419 },*/
    

    { lat: 18.699471, lng: 73.919279 },
    { lat: 18.699497, lng: 73.919386}
   
    ]);
    const [plantOperationalArea, setPlantOperationalArea] = useState([
        { lat: 18.698720, lng: 73.919320 },
        { lat: 18.698735, lng: 73.919177 },

        /*{ lat: 18.699098, lng: 73.919206 },*/
        /* { lat: 18.699063, lng: 73.919419 },*/
        { lat: 18.699471, lng: 73.919259 },
        { lat: 18.699497, lng: 73.919386 }
    ]);
    const onEdit = useCallback((e) => {
        const newPath = e.getPath().getArray().map(latlng => ({
            lat: latlng.lat(),
            lng: latlng.lng()
        }));
        setPlantArea(newPath);
    }, []);
    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={17}
        >
            <Polygon
                paths={plantArea}
                editable={true}
                draggable={true}
                onMouseUp={onEdit}
            />
            <Polygon
                paths={plantOperationalArea}
                editable={true}
                draggable={true}
                onMouseUp={onEdit}
            />
        </GoogleMap>
    ) : <></>;
};
export default MapComponent;