//AddRobot.js
import React, { useState, useEffect } from 'react';
import "../../Assets/Styles/Form.css";
import Axios from 'axios';
import * as FaIcons from "react-icons/fa";
import { hasJWTToken } from "../Account/RouteGuard";
import Select from 'react-select';
import Form from 'react-bootstrap/Form';

export const PlantBlock = () => {
    const _userID = sessionStorage.getItem("user_id");
    let _token = sessionStorage.getItem("token");
    if (_token) _token = "Token exists";
    let [responseData, setResponseData] = useState([]);
    const [plantOption, setPlantOption] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    let [formData, setFormData] = useState({
        plant_id: null,
        block_id: null,
        block_name: '',
        user_id: _userID,
        jwt_token: _token,
        block_mode: 'Insert',
        message: '',
    });
    
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
   
    const handleSubmit = (e) => {
        e.preventDefault();
        var newErrors = validateForm(formData);
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            formData.block_mode = "Insert";
            formData.user_id = 1;
            let objBlockData = Axios.post("/PlantBlock", formData)
            formData.block_id = null;
            formData.block_name = '';
            formData.message = "Added new block successfully!";
            console.log('Form submitted successfully!');
        } else {
            formData.message = 'Block submission failed due to validation errors.'
            console.log('Form submission failed due to validation errors.');
        }
    };

    const validateForm = (data) => {
        const errors = {};
        if (!data.plant_id.trim()) {
            errors.plant_id = 'Plant ID is required';
        } else if (data.plant_id.length > 10) {
            errors.plant_id = 'Plant ID should not be less than or more than 10 digits';
        }

        if (!data.block_name.trim()) {
            errors.block_name = 'Robot name is required';
        } else if (data.block_name.length > 50) {
            errors.block_name = 'Robot name should not be less than or more than 50 characters';
        }

        return errors;
    };
    const handleSelectChange = (e) => {
        formData.plant_id = e.target.value;
    };
    useEffect(() => {
        fetchPlantList();
    }, [])
    useEffect(() => {
        console.log(plantOption)
    }, [plantOption])
    const fetchPlantList = async () => {
        var userID = sessionStorage.getItem("user_id");
        let response = await Axios("/PlantBlock/GetPlantList?paramid=1&=uid="+userID+"&pn=PlantBlock&dn=PlantList");
        setPlantOption(response.data);
    }
    return (
        <div className="container-ul">
            <div className="form-container">
            <h2 className="form-title"><FaIcons.FaRegEdit /> Add New Block</h2>
            <span className="form-message">
                {formData.message}
            </span>
            <form onSubmit={handleSubmit}>
                <div>
                    <Form.Select name="plant_id" onChange={handleSelectChange}>
                        {plantOption.map(opt => (
                            <option value={opt.plant_id}>{opt.plant_name}</option>
                        ))}
                    </Form.Select>
                </div>
                {/*<div>*/}
                {/*    <input*/}
                {/*        className="form-input"*/}
                {/*        type="number"*/}
                {/*        maxLength="10"*/}
                {/*        name="plant_id"*/}
                {/*        value={formData.plant_id}*/}
                {/*        onChange={handleChange}*/}
                {/*        placeholder="Enter plant ID (Max limit 10 digits)"*/}
                {/*    />*/}
                {/*    {errors.plant_id && (*/}
                {/*        <span className="error-message">*/}
                {/*            {errors.plant_id}*/}
                {/*        </span>*/}
                {/*    )}*/}
                {/*</div>*/}
                <div>
                    <input
                        className="form-input"
                        type="text"
                        name="block_name"
                        maxLength="50"
                        value={formData.block_name}
                        onChange={handleChange}
                        placeholder="Enter block name (Max limit 50 chars)"
                    />
                    {errors.block_name && (
                        <span className="error-message">
                            {errors.block_name}
                        </span>
                    )}
                </div>
                <div class="row">
                    <div class="col-9"><button className="submit-button" type="submit">Submit</button></div>
                    <div class="col-3"><a className="btndelete" href="#/SearchBlock">Search Block</a></div>
               </div>
            </form>
            </div>
        </div>
    );
}
export default PlantBlock;