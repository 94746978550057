//*** Import React Library
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Table from "react-data-table-component";
import Button from "react-bootstrap/Button";
import Axios from 'axios'
import "../../Assets/Styles/robot.css";
import { Route, Routes } from 'react-router-dom';
import { hasJWTToken } from "../Account/RouteGuard";
///*** Global Variables 
var PlantID =null;
//***End Global Variables
//let _plantID = (new URLSearchParams(window.location.search)).get("pid")
//PlantID = _plantID;
const columns = [
    { name: "Sr. No.", selector: (row) => row.id +1, sortable: true },
    { name: "Robot Name", selector: (row) => row.robotName, sortable: true },
    { name: "User Name", selector: (row) => row.userName, sortable: true },
    { name: "Broadcast Status", selector: (row) => row.broadcastStatus, sortable: true },
    { name: "Date and Time (HH:MM:SS)", selector: (row) => row.broadcastDate, sortable: true, }
];
const customStyles = {
    table: {
        style: {
            border: "none",
            fontSize: "100%"
        },
    },
    header: {
        backgroundColor: "transparent",
    },
    rows: {
        style: {
            backgroundColor: "transparent",
            /*  fontSize: "100%"*/
        },
    },
    headRow: {
        style: {
            backgroundColor: "transparent",
            fontSize: "110%"
        },
    },
    pagination: {
        style: {
            backgroundColor: "transparent",
            fontWeight: "600",
        },
    },
    headCells: {
        style: {
            borderBottom: "none",
            fontWeight: "700",
            fontSize: "110%"
        },
    },
};
export const PlantRobotsBroadcastStatus = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [data, setData] = useState([])
    const [List, setList] = useState([])
    const [SelectedList, setSelectedList] = useState([])
    const [MasterChecked, setMasterChecked] = useState(false)
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        let tempList = data;
        const filteredData = tempList.filter((event) =>
            Object.values(event).some(
                (value) => typeof value === "string" && value.includes(searchTerm)
            )
        );
        if (event.target.value != null) {
            setList(filteredData);
        }
        if (event.target.value == "") {
            setList(data);
        }
    };
    const filteredData = List.filter((event) =>
        Object.values(event).some(
            (value) => typeof value === "string" && value.includes(searchTerm)
        )
    );
    const handleRowSelected = (rows) => {
        setData(rows.selectedRows);
    };
    //const contextActions = (
    //    <button onClick={() => alert(data.map((r) => r.robotID))}>
    //        Show Selected Rows
    //    </button>
    //);
    useEffect(() => {
        fetchData();
    }, [])
    useEffect(() => {
        console.log(data)
    }, [data])
    const fetchData = async () => {
        var plant_id = sessionStorage.getItem("user_plantid");
        //const response = await Axios("/PlantRobotsBroadcastStatus?pid=" + plant_id + "&rt='AllDate'");
        const response = await Axios("/PlantRobotsBroadcastStatus?pid=" + plant_id + "&rt='AllDate'");
        setData(response.data)
        setList(response.data)
    }
    return (
        <>
         {hasJWTToken() ? (
             <div>
                <h3 style={{ margin: "2%", marginLeft:"2%" }}>Plant Robots Connected Status</h3>
                <div
                style={{
                    border: "1px solid #c0c0c0",
                    borderRadius: "5px",
                    margin: "2%",
                    backgroundColor: "#fff",
                }}
            >
                <div className="container dvscroll" style={{ width: "100%", height: "70%", fontSize: "92%" }} >
                    <div className="row dvscroll">
                        <div className="col-sm-12">
                            <input
                                type="text"
                                placeholder="Search Results"
                                style={{
                                    width: "20%",
                                    height: "30px",
                                    margin: "10px",
                                    float: "right",
                                    border: "none",
                                    padding: "1.5%",
                                    borderRadius: "5px",
                                }}
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                            <p></p>
                            <DataTable className="table .table-sm table-hover" sort={true} pagination={true}
                                search={true}
                                columns={columns}
                                data={filteredData}
                                highlightOnHover={true}
                                customStyles={customStyles}
                            >
                            </DataTable>
                        </div>
                    </div>
                </div>
                </div>
             </div>
            ) :
                (
                    <Route to={{ pathname: '#/login' }} />
                )
            }

        </>
    );
};
